<template>
  <div class="main_container">
    <div class="task_detail_container">
      <!-- 详情页头部 -->
      <detail-title
        :backToPath="'/user/task-manager'"
        :descriptionList="descriptionList"
      ></detail-title>

      <!-- 详情信息列表 -->
      <task-description
        :descriptionList="descriptionList">
      </task-description>

    </div>
    <!-- 任务相关信息 -->
    <task-related-info></task-related-info>

  </div>
</template>

<script>
import api from '@/api/index'
import TaskRelatedInfo from '@/components/task-detail/related-info/index.vue'
import DetailTitle from '@/components/task-detail/title/index.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'

export default {
  components: {
    TaskRelatedInfo,
    DetailTitle,
    TaskDescription
  },
  data () {
    return {
      serviceVisible: false,
      descriptionList: {}
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getTaskDetail()
  },
  methods: {
    getTaskDetail () {
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data ? res.data.data : {}
        } else {
          this.$message.error('请求出错')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main_container {
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 1200px;
}
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  .task_submit {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
}
</style>
